import React from "react";
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HashRouter, Route, Switch } from 'react-router-dom'


import Error from "./Components/Error";
import Saint from "./Components/Saint";


function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route path="/error">
            <Error />
          </Route>
          <Route path="/">
            <Saint />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
