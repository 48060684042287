import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite/no-important";
import { useParams, useRouteMatch, Switch, Route } from "react-router-dom";
import Axios from 'axios';


import Countdown from "./Countdown";

class Home extends React.Component {

  constructor(props){
      super(props);  
      this.state = {
        closed: true
      }
      this.submit = this.submit.bind(this);
      this.toggleClosed = this.toggleClosed.bind(this);     
      this.handleChange = this.handleChange.bind(this);
      this.play = this.play.bind(this);
      this.pause = this.pause.bind(this);      
      this.url = "https://vol1a.s3.amazonaws.com/femme.mp3";
      this.audio = new Audio(this.url);
    this.audio.addEventListener('ended', function () {
      this.currentTime = 0;
      this.play();
    }, false);
      
  }

  componentDidMount(){
    var that = this;
    // this.pause()
    // this.play()
  }

  handleChange(event) {
    console.log("handleChange value:", event.target.value);
    var field = event.target.name
    var newState = {}
    newState[field] = event.target.value
    this.setState(newState)
  }

  toggleClosed(){
    if(this.state.closed){
      this.setState({
        closed: false
      })
    }
    else{
      this.setState({
        closed: true
      })
    }
  }

  submit(){

      console.log('hit submit', this.state)
      var that = this;

      Axios.post('https://future-api-2.herokuapp.com/saintlist2',{
        email: that.state.email,
        address: that.state.address,
        name: that.state.name,
        telegram: that.state.telegram
      })
      .then(function (response) {
        console.log('response_back', response.data)
        var data = response.data
        that.setState({
          submitted: true
        })
      })
      .catch(function (error) {
        console.log('This is a bad response',  error);    
        });      
  }

  play = () => {
  this.setState({ play: true, pause: false })
    this.audio.play();
  }
  
  pause = () => {
  this.setState({ play: false, pause: true })
    this.audio.pause();
  }
  

  render(){

  var that = this
  console.log('hit render in home')
  console.log('music', this.state.play, this.state.pause)

  var icon = "https://i.ibb.co/6gp7LCH/vol-icon.png"

  var rose = <img src="saint_rose.jpg" border="0"/>
  var logo = <img src="saint_logo.png" border="0"/>

  // return <div className="splash saint">
  //   {rose}
  // </div>

  const currentDate = new Date();
  const year =
    currentDate.getMonth() === 11 && currentDate.getDate() > 23
      ? currentDate.getFullYear() + 1
      : currentDate.getFullYear();


  var form = <div className="saint">
    <div className="logo">
     {logo}
    </div>
    <div className="rose">
      {rose}
    </div>
  <div className="form1">
  <h3>Email Address</h3>
  <input onclick="this.select()" name="email" onChange={this.handleChange}/>
  <h3>Telegram</h3>
  <input onclick="this.select()" name="telegram" onChange={this.handleChange}/>
  <h3>Ethereum Wallet Address (or ENS)</h3>
  <input onClick="this.select()" name="address" onChange={this.handleChange}/>
  <h3>Name</h3>
  <input onclick="this.select()" name="name" onChange={this.handleChange}/>
  <button onClick={this.submit}>Submit</button>
  </div>
  </div>



  var main = <div className="saint">
    <div className="teaser">
      <Countdown date={`${year}-11-11T16:00:00`} />
    </div>
    <div className="rose">
      {rose}
    </div>
    { that.state.submitted ? <div></div>: <div className="logo">
     {logo}
    <p id="sub">Les Pétales de Saint Rien de la Genèse begins 11.11.22, learn more <a href="https://twitter.com/SaintRien/status/1587097125195653122">here</a></p>


    </div> }

    <div className="mainContent">

    { that.state.submitted ? <h3 className="center">Welcome to Le Culte de Saint Rien.</h3> : <button onClick={this.toggleClosed}>Proganda Enrollment</button> }

      <div className="music">
        <img src='./femme.png'/>
        <div className="songDetails">
        <h3>Le Femme</h3>
        <p>Coming Soon</p>
        </div>
        { this.state.play ? 
          <span class="material-symbols-outlined" onClick={this.pause}>
          pause
          </span> 
          : 
          <span class="material-symbols-outlined" onClick={this.play}>
          play_arrow
          </span>
        }    
      </div>

    </div>

    <div className="vol">


      <a href="https://twitter.com/voldao_">
      <img src={icon} />
      </a>
    </div>
   </div>

  if(this.state.closed || this.state.submitted){
    return main
  }
  else {
    return form
  }
  
  }
}

export default Home
